<template>
  <div class="container-extend container-inset overflow-hidden">
    <swiper
      v-bind="swiperConfig"
      class="!overflow-visible"
      @swiper="$emit('swiper', $event)"
    >
      <slot />
    </swiper>
  </div>
</template>

<script setup lang="ts">
import {Swiper as SwiperInstance} from 'swiper';
import {Swiper} from 'swiper/vue';

defineEmits<{
  (e: 'swiper', swiper: SwiperInstance): void
}>();

const swiperConfig = {
  slidesPerView: 1.05,
  spaceBetween: 16,
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 12,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 18,
    },
    1536: {
      slidesPerView: 3.5,
      spaceBetween: 24,
    },
  },
};
</script>

<style scoped>
::v-deep(.swiper-slide) {
  height: auto;
}
</style>

<style src="swiper/css" />
